.wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.questions {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 417px;
  max-width: 95vw;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(9, 30, 66, 0.16), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
}
.header {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 25px;
  line-height: 24px;
  color: #000000;
}

.columnsHeader {
  padding: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  background: #eff6ff;
  border-top: 0.5px solid #94a3b8;
}

.columnsHeader span {
  margin-left: auto;
}

.question {
  padding: 12px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-top: 0.5px solid #94a3b8;
  cursor: pointer;
}

.active {
  background: #eff6ff;
}

.question span {
  margin-left: auto;
}

.moreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  width: 244px;
  height: 51px;
  background: #10a37f;
  border-radius: 14px;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 26px;
}
.question_text{
  width: calc(100% - 35px);
}

@media screen and (max-width: 576px){
  .questions{
    width: 100%;
  }
  .wrapper{
    padding-left: 10px;
  }
}