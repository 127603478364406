.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  height: 51px;
  border-radius: 14px;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 26px;
  max-width: 382px;
}

.green {
  background: #10a37f;
}

.red {
  background: #a33310;
}


