@font-face {
  font-family: "ColfaxAI";
  src: url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff2) format("woff2"),
    url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ColfaxAI";
  src: url(https://cdn.openai.com/API/fonts/ColfaxAIBold.woff2) format("woff2"),
    url(https://cdn.openai.com/API/fonts/ColfaxAIBold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

*{
  box-sizing: border-box;
}

.wrapper {
  padding: 5px 0;
}

body{
  margin: 0;
}

.main,
.main input {
  font-size: 16px;
  line-height: 24px;
  color: #353740;
  font-family: "ColfaxAI", Helvetica, sans-serif;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}
.main .icon {
  width: 34px;
}
.main h3 {
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  color: #202123;
  margin: 16px 0 40px;
  text-align: center;
}
.main form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
}
.main input[type="text"] {
  background: #eff6ff;
  border: 1px solid #3b82f6;
  border-radius: 14px;
  padding: 16px 32px;
  width: 300px;
  height: 56px;
}
.main ::placeholder {
  color: #8e8ea0;
  opacity: 1;
}
main input.main__input{
  outline-color: transparent;
  outline-width: 0px;
  transition: .2s ease-in-out;
}
main input.main__input:hover{
  border: 1px solid orange;
  transition: .2s ease-in-out;
}
.main .result {
  font-weight: bold;
  margin-top: 40px;
}

.questions {
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}


@media screen and (max-width: 576px){
  .TopQuestions_questions{
  width: 100%;
  }
}

@media screen and (max-width: 576px){
  .main form{
    width: 100%;
  }
}