.wrapper {
  max-width: 95vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.questionHeader {
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #d3d3d3;
  align-self: start;
}

.question {
  align-self: start;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-left: 28px;
  margin-top: 10px;
}

.answerHeader {
  width: 421px;
  max-width: 95vw;
  height: 162px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 7px;
  margin-top: 36px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #d3d3d3;
  padding: 7px 14px;
}

.answerUpdated {
  border: none;
}

.answer {
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  color: #000000;
  margin-top: 10px;
  margin-left: 7px;
  word-wrap: break-word;
}

.editAnswerHeader {
  width: 421px;
  max-width: 95vw;
  height: 162px;
  background: #eff6ff;
  border: 1px solid #3b82f6;
  border-radius: 7px;
  margin-top: 36px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #d3d3d3;
  padding: 7px 14px;
}

.editAnswer {
  display: block;
  background: #eff6ff;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  color: #000000;
  margin-top: 10px;
  margin-left: 7px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: calc(100% - 41px);
  max-height: calc(100% - 41px);
  min-height: calc(100% - 41px);
  border: none;
  resize: none;
  max-width: 95vw;
  word-wrap: break-word;
}

.editAnswer:active,
.editAnswer:focus {
  outline: none;
  border: none;
}

.buttons {
  width: 100%;
  max-width: 95vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons div:first-child {
  margin-right: 11px;
}

@media screen and (max-width: 576px){
  .wrapper{
    padding-left: 10px;
    padding-right: 10px;
  }
  .backBtn{
    max-width: 90%;
    width: 100%!important;
  }
  .answerHeader{
    width: 100%;
  }
}